<template>
  <div>
    <!-- <b-button v-b-toggle.collapse-3 class="m-1">Toggle Collapse</b-button> -->
    <div>
      <b-row class="d-flex align-items-end justify-content-end mb-1 mr-1">
        <b-button size="sm" class="mr-1" @click="openConfigModal('open')">
          Report Pdf/Excel configuration
        </b-button>
        <b-button
          size="sm"
          variant="outline-primary"
          :class="visible ? null : 'collapsed'"
          :aria-expanded="visible ? 'true' : 'false'"
          aria-controls="collapse-4"
          @click="visible = !visible"
        >
          {{ visible ? "Hide Filters" : "Show Filters" }}
        </b-button>
      </b-row>
      <b-collapse id="collapse-4" v-model="visible" class="mt-2">
        <b-card>
          <b-row>
            <!-- <b-col md="4">
              <b-form-group label=" Date Range" label-for="mc-revenue-date">
                <flat-pickr
                  @on-change="filterData"
                  v-model="dateRange"
                  placeholder=" Date Range"
                  class="form-control"
                  :config="{ mode: 'range' }"
                />
              </b-form-group>
            </b-col> -->
            <b-col md="3">
              <b-form-group label="Report Type*">
                <v-select
                  :clearable="false"
                  :options="reportByData"
                  v-model="reportBy"
                  :close-on-select="false"
                />
              </b-form-group>
            </b-col>
            <b-col md="3">
              <b-form-group label="Filter By Movies">
                <v-select
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  multiple
                  :reduce="(label) => label.movie_id"
                  @input="filterData"
                  v-model="selectedMovie"
                  placeholder="Select Movies"
                  label="full_title"
                  value="movie_id"
                  :options="optionMovies"
                />
              </b-form-group>
            </b-col>
            <b-col md="3">
              <b-form-group label="Filter By Rights Group">
                <v-select
                  :reduce="(label) => label.lbl_id"
                  @input="filterData"
                  v-model="rights"
                  multiple
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="rights_list"
                  label="lbl_name"
                  value="lbl_id"
                  placeholder="Select Rights Group"
                />
              </b-form-group> </b-col
            ><b-col md="3">
              <b-form-group label="Filter By Sub Rights">
                <v-select
                  :reduce="(label) => label.lbl_id"
                  @input="filterData"
                  v-model="subRights"
                  multiple
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="sub_rights_list"
                  label="lbl_name"
                  value="lbl_id"
                  placeholder="Select Sub Rights"
                />
              </b-form-group>
            </b-col>
            <b-col md="3">
              <b-form-group label=" Filter By Territories">
                <v-select
                  @input="filterData"
                  v-model="territory"
                  multiple
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="territory_list"
                  :reduce="(label) => label.lbl_id"
                  label="lbl_name"
                  value="lbl_id"
                  placeholder="Select Territories"
                />
              </b-form-group>
            </b-col>

            <b-col md="3">
              <b-form-group label="Filter By  Languages">
                <v-select
                  :reduce="(label) => label.lbl_id"
                  @input="filterData"
                  v-model="language"
                  multiple
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="language_list"
                  label="lbl_name"
                  value="lbl_id"
                  placeholder="Select  Languages"
                />
              </b-form-group>
            </b-col>
            <b-col md="3">
              <b-form-group label="Filter By Director">
                <v-select
                  :reduce="(label) => label.lbl_id"
                  @input="filterData"
                  v-model="director"
                  multiple
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="director_list"
                  label="lbl_name"
                  value="lbl_id"
                  placeholder="Select Director"
                />
              </b-form-group>
            </b-col>
            <b-col md="3">
              <b-form-group label="Filter By Cast">
                <v-select
                  :reduce="(label) => label.lbl_id"
                  @input="filterData"
                  v-model="cast"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="cast_list"
                  multiple
                  label="lbl_name"
                  value="lbl_id"
                  placeholder="Select Cast"
                />
              </b-form-group>
            </b-col>
            <b-col md="3">
              <b-form-group label="Filter By Genre">
                <v-select
                  :reduce="(label) => label.lbl_id"
                  @input="filterData"
                  v-model="genre"
                  multiple
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="genre_list"
                  label="lbl_name"
                  value="lbl_id"
                  placeholder="Select Genre"
                />
              </b-form-group>
            </b-col>
            <b-col md="3">
              <b-form-group label="Filter By Countries">
                <v-select
                  @input="filterData"
                  v-model="countryData"
                  multiple
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="country_list"
                  :reduce="(label) => label.lbl_id"
                  label="lbl_name"
                  value="lbl_id"
                  placeholder="Select Countries"
                />
              </b-form-group>
            </b-col>
            <b-col md="3">
              <b-form-group label="Filter By Rights Available From">
                <flat-pickr
                  @on-change="filterData"
                  v-model="availableFrom"
                  placeholder="Select Date"
                  class="form-control"
                  :config="{ ...$flatPickrConfig }"
                /> </b-form-group
            ></b-col>
            <b-col md="3">
              <b-form-group label="Filter By Rights Available Till">
                <flat-pickr
                  @on-change="filterData"
                  v-model="availableTo"
                  placeholder="Select Date"
                  class="form-control"
                  :config="{ ...$flatPickrConfig }"
                /> </b-form-group
            ></b-col>
            <b-col md="3">
              <b-form-group label="Filter By Rights Available As On">
                <flat-pickr
                  @on-change="filterData"
                  v-model="availableAsOn"
                  placeholder="Select Date"
                  class="form-control"
                  :config="{ ...$flatPickrConfig }"
                /> </b-form-group
            ></b-col>
            <b-col md="3">
              <b-form-group label="-">
                <b-button block @click="onfetchReport" variant="primary">
                  Fetch Report
                </b-button></b-form-group
              >
            </b-col></b-row
          ></b-card
        >
      </b-collapse>
    </div>
    <!-- @click="openConfigModal('Export')" -->
    <b-tabs pills align="left">
      <b-row class="d-flex align-items-end justify-content-end mb-1 mr-1">
        <b-button
          :disabled="setDisable"
          @click="exportSalesAvailabilities"
          variant="outline-primary"
          class="mr-1"
        >
          Export
        </b-button>
      </b-row>
      <!-- This tabs content will always be mounted -->
      <b-tab lazy>
        <template #title>
          <!-- <feather-icon icon="TrendingUpIcon" /> -->
          <span>Tabular View</span>
        </template>

        <b-card no-body>
          <b-table
            bordered
            :fields="fields"
            :items="tablerows"
            empty-text="No matching records found"
            show-empty
            responsive
          >
            <template #head()="row">
              <span>{{ row.field.label }}</span>

              <b-form-checkbox
                v-if="row.field.label == 'Right'"
                @change="onfetchReport"
                v-model="groupValues"
                :value="row.field.label"
                >Group {{ row.field.label }}
              </b-form-checkbox>
            </template>
            <template #cell(subRightLabelName)="data">
              <div v-if="groupValues.length && data.item.right_parent_id">
                {{ getParentRight(data.item.right_parent_id) }}
              </div>

              <div v-else>
                <!-- groupValues.length
                    ? data.item.right_name
                    : data.item.right_name
                    ? data.item.right_name +
                      (data.item.subRightLabelName
                        ? " (" + data.item.subRightLabelName + ")"
                        : "")
                    : data.item.subRightLabelName
                    ? data.item.subRightLabelName
                    : data.item.right_name -->
                {{
                  groupValues.length
                    ? data.item.right_name
                    : data.item.right_name
                    ? data.item.right_name
                    : data.item.subRightLabelName
                    ? data.item.subRightLabelName
                    : data.item.right_name
                }}
              </div>
            </template>
            <template #cell(right_name)="data">
              <div v-if="groupValues.length && data.item.right_parent_id">
                {{ getParentRight(data.item.right_parent_id) }}
              </div>

              <div v-else>
                {{
                  data.item.subRightLabelName
                    ? data.item.subRightLabelName
                    : data.item.right_name
                }}
              </div>
            </template>

            <template #cell(sold)="data">
              <div v-if="data.item.sold.length">
                <span v-for="(item, index) in data.item.sold" :key="index">
                  <b-badge variant="light-danger">
                    {{ moment(item.start).format("DD/MM/YYYY") }} -
                    {{ moment(item.end).format("DD/MM/YYYY") }}
                    {{
                      item.exclusive && item.exclusive == "1"
                        ? "(Exclusively Sold)"
                        : ""
                    }}</b-badge
                  ><br
                /></span>
              </div>

              <div v-else>Not Sold</div>
            </template>

            <!-- <template #cell(exclusive)="data">
              {{ data.item.exclusive == "1" ? "Yes" : "No" }}
            </template> -->
            <template #cell(available)="data">
              <div v-if="data.item.isAvailable == true">
                <div v-if="data.item.available.length">
                  <span
                    v-for="(item, index) in data.item.available"
                    :key="index"
                  >
                    <b-badge variant="success">
                      {{ moment(item.start).format("DD/MM/YYYY") }} -
                      {{ moment(item.end).format("DD/MM/YYYY") }}</b-badge
                    ><br />
                    <!-- <span v-if="index == data.item.available.length - 1">
                      <b-badge variant="success">
                        {{
                          moment(data.item.sold.at(-1).end)
                            .add(1, "days")
                            .format("DD/MM/YYYY")
                        }}
                        -
                        {{
                          moment(data.item.contract_end).format("DD/MM/YYYY")
                        }}</b-badge
                      ><br />
                    </span> -->
                  </span>
                </div>

                <div v-else>
                  Not Available To Sell
                  <!-- <span
                    v-for="(item, index) in data.item.contract_validity"
                    :key="index"
                  >
                    <b-badge variant="success">
                      {{ moment(item.start).format("DD/MM/YYYY") }} -
                      {{ moment(item.end).format("DD/MM/YYYY") }}<br /></b-badge
                  ></span> -->
                </div>
              </div>
              <div v-else>Not Available To Sell</div>
            </template>
          </b-table>
        </b-card>
      </b-tab>

      <b-tab lazy>
        <template #title>
          <!-- <feather-icon icon="TrendingUpIcon" /> -->
          <span>Grid View </span>
        </template>

        <vue-good-table
          v-if="gridViewData.length"
          ref="myCustomTable"
          :group-options="{
            enabled: true,
            headerPosition: 'top',
            collapsable: true,
          }"
          :columns="columns"
          :rows="gridViewData"
        >
          <template slot="table-header-row" slot-scope="props">
            <div>
              <div class="alert-body">
                <b
                  ><h4>{{ props.row.title.toUpperCase() }}</h4></b
                >
              </div>
              <b-row>
                <b-col md="1">
                  <b-img
                    :src="BASE_URL_FILES + props.row.movie_art"
                    rounded
                    width="62px"
                  />
                </b-col>
                <b-col md="4">
                  <span style="color: grey"
                    >ISAN : {{ props.row.isan_number }}<br />
                    Unique ID : {{ props.row.movie_unique }}<br />
                    Year : {{ props.row.year }} <br />
                    Duration : {{ props.row.duration }} min <br />
                    Genre : {{ props.row.movie_genere }}</span
                  >
                  <br />
                </b-col>
                <b-col md="3">
                  <span style="color: grey">
                    Original Languages: {{ props.row.original_languages }}
                    <br />
                    Available Languages: {{ props.row.available_languages }}
                    <br />
                    Available Subtitles: {{ props.row.available_subTitles
                    }}<br />
                    Country: {{ props.row.movie_country }}
                  </span>
                </b-col>

                <b-col md="4">
                  <span style="color: grey">
                    Director : {{ props.row.director_names }} <br />
                    Cast: {{ props.row.cast_names }}<br />
                    Synopsis: {{ props.row.movie_synopsis }}
                  </span>
                </b-col>
              </b-row>

              <b-row class="m-1">
                <h6>Rights Release Dates</h6>
                <table>
                  <tr>
                    <th>Rights</th>
                    <th>Territories</th>
                    <th>Languages</th>
                    <th>Release Date</th>
                    <th>Show In PDF</th>
                  </tr>
                  <tr
                    v-for="(item, index) in props.row.movie_released_array"
                    :key="index"
                  >
                    <td>{{ item.right_name }}</td>
                    <td>{{ item.territory_name }}</td>
                    <td>{{ item.language_name }}</td>
                    <td>
                      {{
                        item.date ? moment(item.date).format("DD/MM/YYYY") : ""
                      }}
                    </td>
                    <td>
                      <b-form-checkbox checked="true"></b-form-checkbox>
                    </td>
                  </tr>
                  <tr v-if="props.row.movie_released_array.length <= 0">
                    <td colspan="5" class="text-muted">
                      Release Dates Not Added
                    </td>
                  </tr>
                </table>
              </b-row>
              <b-row class="m-1">
                <b-col
                  md="6"
                  class="d-flex align-items-start justify-content-start"
                >
                  <b-button
                    class="mr-1"
                    @click="onMovieClicked(props.row, 'A')"
                    variant="outline-danger"
                    size="sm"
                    >Show Rights Available to sell / Open Rights</b-button
                  >
                </b-col>

                <!-- <b-col md="6" class="d-flex align-items-end justify-content-end">
              <b-button
                disabled
                class="mr-1"
                variant="outline-success"
                size="sm"
                >Generate PDF</b-button
              >
            </b-col> -->
              </b-row>
            </div>
          </template>
          <template slot="table-row" slot-scope="props">
            <div v-if="props.column.field == 'available'">
              <div v-if="props.row.isAvailable == true">
                <div v-if="props.row.available.length">
                  <span
                    v-for="(item, index) in props.row.available"
                    :key="index"
                  >
                    <b-badge variant="success">
                      {{ moment(item.start).format("DD/MM/YYYY") }} -
                      {{ moment(item.end).format("DD/MM/YYYY") }}</b-badge
                    ><br />
                    <!-- <span v-if="index == props.row.available.length - 1">
                      <b-badge variant="success">
                        {{
                          moment(props.row.sold.at(-1).end)
                            .add(1, "days")
                            .format("DD/MM/YYYY")
                        }}
                        -
                        {{
                          moment(props.row.contract_end).format("DD/MM/YYYY")
                        }}</b-badge
                      ><br />
                    </span> -->
                  </span>
                </div>
                <div v-else>
                  Not Available To Sell
                  <!-- <span
                    v-for="(item, index) in props.row.contract_validity"
                    :key="index"
                  >
                    <b-badge variant="success">
                      {{ moment(item.start).format("DD/MM/YYYY") }} -
                      {{ moment(item.end).format("DD/MM/YYYY") }}<br /></b-badge
                  ></span> -->
                </div>
              </div>
              <div v-else>Not Available To Sell</div>
            </div>
            <div v-else-if="props.column.field == 'sold'">
              <div v-if="props.row.sold.length">
                <span v-for="(item, index) in props.row.sold" :key="index">
                  <b-badge variant="danger">
                    {{ moment(item.start).format("DD/MM/YYYY") }} -
                    {{ moment(item.end).format("DD/MM/YYYY") }}
                    {{
                      item.exclusive && item.exclusive == "1"
                        ? "(Exclusively Sold)"
                        : ""
                    }}</b-badge
                  >
                  <br
                /></span>
              </div>
              <div v-else>Not Sold</div>
            </div>
            <div v-else-if="props.column.field == 'subRightLabelName'">
              {{
                props.row.right_name
                  ? props.row.right_name +
                    " (" +
                    props.row.subRightLabelName +
                    ")"
                  : props.row.subRightLabelName
                  ? props.row.subRightLabelName
                  : props.row.right_name
              }}
            </div>
          </template>
        </vue-good-table>
      </b-tab>
    </b-tabs>
    <div class="m-1" v-if="gridViewData && tablerows.length">
      <b-row class="m-1">
        <b-col
          class="d-flex align-items-center justify-content-center mb-1 mb-md-0"
        >
          <b-button @click="getReportData" variant="success">
            Load More Data
          </b-button>
        </b-col>
      </b-row>
    </div>

    <b-modal
      ref="configModal"
      title="Report Configuration ( Fields to be exported in Excel/Pdf )"
      size="xl"
      hide-footer
    >
      <b-row v-if="existingReportConfigData.length">
        <b-col md="4">
          <b-form-group label="Select From Existing Templete">
            <v-select
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :reduce="(label) => label.config_id"
              @input="filterTemplete"
              v-model="selectedTemplete"
              placeholder="Select Saved Templete"
              label="templete_name"
              value="config_id"
              :options="existingReportConfigData"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row class="my-1">
        <b-col
          class="m-2"
          v-for="(item, index) in configFields"
          :key="index"
          sm="2"
        >
          <b-form-checkbox
            v-model="item.value"
            :checked="item.value"
            switch
            inline
            >{{ item.name }}</b-form-checkbox
          >
        </b-col>
      </b-row>

      <hr />
      <div v-if="!selectedTemplete">
        <!-- <b-form-group label="Report Templete Name"> -->
        <b-row>
          <b-col md="4">
            <b-form-input
              placeholder="Report Templete Name"
              v-model="reportFilterTempleteName"
            />
          </b-col>
          <!-- </b-form-group> -->
          <b-col md="2">
            <b-button
              :disabled="!reportFilterTempleteName"
              variant="outline-primary"
              @click="saveConfig('1')"
            >
              Save
            </b-button>
          </b-col>
        </b-row>
      </div>
      <b-row>
        <b-col
          md="12"
          class="d-flex align-items-end justify-content-end mb-1 mb-md-0"
        >
          <b-button
            :disabled="setDisable"
            @click="exportSalesAvailabilities"
            variant="primary"
            v-if="modalEvent == 'Export'"
          >
            Export Data
          </b-button>
        </b-col>
      </b-row>
    </b-modal>
  </div>
</template>

<script>
import {
  BCard,
  BFormCheckbox,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BTab,
  BTabs,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BImg,
  BFormGroup,
  BCardText,
  BCollapse,
  BAlert,
} from "bootstrap-vue";
import { VueGoodTable } from "vue-good-table";

import "vue-good-table/dist/vue-good-table.css";
import Ripple from "vue-ripple-directive";

import vSelect from "vue-select";
import ToastificationContentVue from "@core/components/toastification/ToastificationContent.vue";
import { getAllMovie } from "@/apiServices/MovieServices";
import {
  GetSalesAvailableReport,
  SaveReportConfig,
} from "@/apiServices/ReportsServices";
import moment from "moment";
import { downloadFromURL } from "@/utils/helpers";
import flatPickr from "vue-flatpickr-component";
import { getAllData } from "@/apiServices/MastersServices";
import qs from "qs";

export default {
  components: {
    BAlert,
    BTabs,
    BTab,
    BFormCheckbox,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BImg,
    flatPickr,
    BFormGroup,

    vSelect,
    ToastificationContentVue,
    VueGoodTable,
    BCardText,
    BCollapse,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      BASE_URL: process.env.VUE_APP_BASEURL,
      BASE_URL_FILES: process.env.VUE_APP_FILESURL,
      optionMovies: [],
      territory_list: [],

      selectedTemplete: null,
      modalEvent: null,

      territory: null,
      language_list: [],
      language: null,
      rights_list: [],
      groupValues: [],
      sub_rights_list: [],
      existingReportConfigData: [],
      rights: null,
      subRights: null,
      visible: false,
      director_list: [],
      director: null,
      cast_list: [],
      cast: null,
      reportBy: ["Sales / Availabilities"],
      reportByData: [
        "Sales / Availabilities",
        // "Open Rights",
        "Acqusition",
        "Sold",
        "Reversionary Rights",
      ],
      reportFilterTempleteName: "",
      genre_list: [],
      genre: null,
      countryData: null,
      availableFrom: null,
      setDisable: false,
      availableTo: null,
      availableAsOn: null,

      country_list: [],
      country: null,
      selectedMovie: null,
      moment,

      reportData: [],
      columns: [
        {
          label: "Title",
          field: "title",
        },
        {
          label: "Right",
          field: "subRightLabelName",
        },
        {
          label: "Territory",
          field: "territory_name",
        },
        {
          label: "Language",
          field: "language_name",
        },
        {
          label: "Available From (DD/MM/YYYY)",
          field: "available",
        },
        {
          label: "Sold From (DD/MM/YYYY)",
          field: "sold",
        },
      ],
      tablerows: [],
      gridViewData: [],
      configFields: [],
      lastUnique: null,
      fields: [
        { key: "title", label: "Title", class: "text-left" },
        { key: "subRightLabelName", label: "Right", class: "text-left" },
        { key: "right_name", label: "Sub-Right", class: "text-left" },
        { key: "territory_name", label: "Territory", class: "text-left" },
        { key: "language_name", label: "Language", class: "text-left" },
        // { key: "exclusive", label: "Exclusive", class: "text-left" },
        // {
        //   key: "contract_validity",
        //   label: "contract_validity",
        //   class: "text-left",
        // },
        {
          key: "available",
          label: "Available From (DD/MM/YYYY)",
          class: "text-center",
          variant: "success",
        },
        {
          key: "sold",
          label: "Sold From (DD/MM/YYYY)",
          class: "text-center",
          variant: "warning",
        },
      ],
      pagination: {
        currentPage: 1,
        total: null,
        perPage: 10,
      },

      reportView: [],
      reportViewList: ["Tabular", "Grid"],
      reportConfigList: [
        {
          name: "Yes",
          value: "Y",
        },
        {
          name: "No",
          value: "N",
        },
      ],
    };
  },

  watch: {},
  methods: {
    getParentRight(parentId) {
      let prnt = this.rights_list.filter((x) => {
        return x.lbl_id == parentId;
      });

      if (prnt.length) {
        return prnt[0].lbl_name;
      } else {
        return "";
      }
    },
    getGroupValue(label, value) {},
    async exportSalesAvailabilities(data) {
      this.lastUnique = null;
      this.setDisable = true;

      this.$toast({
        component: ToastificationContentVue,
        props: {
          title: "Report Will be downloaded in 4-5 min please wait..! ",
          icon: "EditIcon",
          variant: "success",
        },
      });
      let payload = {
        movie_id: data && data.movie_id ? data.movie_id : null,
        type: data && data.type ? data.type : null,
        filterMovieIds: this.selectedMovie,
        filteredRights: this.rights,
        filteredSubRights: this.subRights,
        reportBy: this.reportBy,
        filteredTerritory: this.territory,
        filteredLanguage: this.language,
        filteredDirector: this.director,
        filteredCast: this.cast,
        filteredGenre: this.genre,
        filteredCountry: this.countryData,
        filteredAvailableFrom: this.availableFrom,
        filteredAvailableTo: this.availableTo,
        filteredAvailableAsOn: this.availableAsOn,
        lastUnique: this.lastUnique,
        groupValues: this.groupValues,
      };
      const queryString = qs.stringify(payload);

      fetch(
        this.BASE_URL + `/report/exportSalesAvailabilitiesData?${queryString}`,
        {
          headers: {
            "content-type": "application/Json",
            authorization: localStorage.getItem("access_token"),
          },
        }
      )
        .then((response) => response.blob())
        .then((blob) => {
          this.setDisable = false;
          var _url = window.URL.createObjectURL(blob);
          downloadFromURL(_url, "Sales_Availabilities", "xlsx");
        })
        .catch((err) => {
          this.setDisable = false;
          console.error(err);
        });
    },
    // rowClass(item, type) {
    //   if (!item || type !== "row") return;
    //   if (item.exclusive == "1") return "table-danger";
    // },
    filterTemplete() {
      let checkTemplete = this.existingReportConfigData.filter((x) => {
        return x.config_id == this.selectedTemplete;
      });

      if (checkTemplete.length) {
        this.configFields = JSON.parse(checkTemplete[0].config_data);
      }
    },
    async saveConfig(type) {
      try {
        let payload = {
          type: type,
          templete_name: this.reportFilterTempleteName,
          config_data: this.configFields,
        };
        const response = await SaveReportConfig(payload);
        if (response.data.status) {
          if (this.modalEvent != "Export") {
            this.reportFilterTempleteName = "";
            this.getReportData();
          }
          return this.$toast({
            component: ToastificationContentVue,
            props: {
              title: response.data.message || "Templete Saved",
              icon: "EditIcon",
              variant: "success",
            },
          });
        }
      } catch (err) {
        console.error("Error in getting movies list ", err);
      }
    },
    openConfigModal(type) {
      this.modalEvent = type;
      setTimeout(() => {
        this.$refs["configModal"].show();
      }, 100);
    },
    onMovieClicked(rowData, type) {
      this.$refs.myCustomTable.collapseAll();
      // let data = {
      //   movie_id: rowData.movie_id,
      //   type: type,
      // };

      // this.getReportData(data);
    },
    onfetchReport() {
      this.tablerows = [];
      this.gridViewData = [];
      this.configFields = [];
      this.lastUnique = null;
      this.getReportData();
    },
    filterData() {
      return false;
      this.$refs.myCustomTable.collapseAll();
      this.getReportData();
    },
    async GetAllMovie() {
      try {
        const response = await getAllMovie();
        if (response.data) {
          this.optionMovies = response.data.data.data;
        }
      } catch (err) {
        console.error("Error in getting movies list ", err);
      }
    },
    onPageChange() {
      return false;
      this.$nextTick(() => {
        this.getReportData();
      });
    },
    async getAllTerritory() {
      const response = await getAllData({
        master: "territory",
        deleted: "0",
      });
      const response1 = await getAllData({
        master: "sub_right",
        deleted: "0",
      });

      const response2 = await getAllData({
        master: "language",
        deleted: "0",
      });
      const response3 = await getAllData({
        master: "director",
        deleted: "0",
      });
      const response4 = await getAllData({
        master: "cast",
        deleted: "0",
      });
      const response5 = await getAllData({
        master: "genre",
        deleted: "0",
      });
      const response6 = await getAllData({
        master: "country",
        deleted: "0",
      });

      const response7 = await getAllData({
        master: "rights",
        deleted: "0",
      });

      if (response.status == 200) {
        this.territory_list = response.data.data.rows;
      }

      if (response1.status == 200) {
        this.sub_rights_list = response1.data.data.rows.filter((z) => {
          return z.lbl_value1 != "" && z.lbl_value1 != null;
        });
      }
      if (response2.status == 200) {
        this.language_list = response2.data.data.rows;
      }
      if (response3.status == 200) {
        this.director_list = response3.data.data.rows;
      }
      if (response4.status == 200) {
        this.cast_list = response4.data.data.rows;
      }
      if (response5.status == 200) {
        this.genre_list = response5.data.data.rows;
      }
      if (response6.status == 200) {
        this.country_list = response6.data.data.rows;
      }

      if (response7.status == 200) {
        this.rights_list = response7.data.data.rows.filter((z) => {
          return z.lbl_cat == "rights";
        });
      }
    },
    async getReportData(data) {
      // this.$refs.MyTable.collapseAll();
      // this.tablerows = [];
      // this.existingReportConfigData = [];

      let payload = {
        movie_id: data && data.movie_id ? data.movie_id : null,
        type: data && data.type ? data.type : null,
        filterMovieIds: this.selectedMovie,
        filteredRights: this.rights,
        filteredSubRights: this.subRights,
        reportBy: this.reportBy,
        filteredTerritory: this.territory,
        filteredLanguage: this.language,
        filteredDirector: this.director,
        filteredCast: this.cast,
        filteredGenre: this.genre,
        filteredCountry: this.countryData,
        filteredAvailableFrom: this.availableFrom,
        filteredAvailableTo: this.availableTo,
        filteredAvailableAsOn: this.availableAsOn,
        lastUnique: this.lastUnique,
        groupValues: this.groupValues,
      };

      const response = await GetSalesAvailableReport(payload);

      if (!response.data.status) {
        return this.$toast({
          component: ToastificationContentVue,
          props: {
            title: response.data.message || "Data not found!",
            icon: "EditIcon",
            variant: "failure",
          },
        });
      }

      if (response.data.data) {
        this.tablerows.push(...response.data.data.dataRows);
        this.gridViewData.push(...response.data.data.gridViewData);

        this.configFields = response.data.data.reportConfigArray;

        this.lastUnique =
          this.gridViewData[this.gridViewData.length - 1].movie_unique;

        this.existingReportConfigData =
          response.data.data.existingReportConfigData;
      }
    },
  },

  beforeMount() {
    this.getReportData();
    this.GetAllMovie();
    this.getAllTerritory();
  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}

table {
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 100%;
  font-size: 13px;
}

td,
th {
  border: 1px solid #dddddd;
  text-align: left;
  padding: 8px;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
